let doctors = document.querySelectorAll('.medic__doctor');
let tables = document.querySelectorAll('.medic__table');

// for(var i=0; i < doctors.length; i++){
//     doctors[i].addEventListener('click', function(e){
//         resetTables();
//         e.target.classList.add('active');
//         document.getElementById('table' + e.target.getAttribute('data-table')).classList.remove('hide');
//     })
// }

function resetTables() {
    for(var i=0; i < doctors.length; i++){
        doctors[i].classList.remove('active');
        tables[i].classList.add('hide');
    }
}


// SMOOTH SCROLL

$(document).ready(function(){
    $("a").on('click', function(event) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 700, function(){
          window.location.hash = hash;
        });
      }
    });
  });